import { useEffect, useMemo, useContext, useCallback } from "react";
import { useMediaQuery } from "@flash-tecnologia/hros-web-ui-v2";
import { useAuth } from "@flash-hros/auth-helper";
import {
  usePermissions,
  useSelectedCompany,
  useLegacyPermissions,
  useContractModules,
} from "@flash-tecnologia/hros-web-utility";
import { getMenuGroups } from "./menuGroups";
import { Context } from "../../context";
import { getCompany, getPeopleContractModules } from "../../api";
import Mobile from "./Mobile";
import Desktop from "./Desktop";
import { Company } from "./types";
import { useExpensesContracts } from "../../hooks/useExpensesContracts";
import { useUnleashToggles } from "../../utils/useUnleashToggles";
import { useCheckCorporateCardUser } from "../../hooks/useCheckCorporateCardUser";
import { checkUnleashToggle } from "../../utils/checkUnleashPermission";
import { useFolhaCertaUser } from "../../hooks/useFolhaCertaUser";
import { NotificationsMenuProvider } from "../../context/notification";

const Menu = () => {
  const { dispatch } = useContext(Context);
  const accessToken = useAuth((state) => state.accessToken);
  const { companies, isAdmin } = usePermissions();
  const peopleContractModules = useContractModules();
  const { selectedCompany } = useSelectedCompany();
  const unleashToggles = useUnleashToggles({ companyId: selectedCompany.id });
  const { checkSectionPermission, permissions, isLoading } =
    useLegacyPermissions();
  const companyPermissions = useMemo(() => {
    if (companies && selectedCompany?.id) {
      const selectedCompanyPermissions = companies.find(
        (company) => company.id === selectedCompany.id
      );
      return selectedCompanyPermissions;
    }
    return null;
  }, [companies, selectedCompany]);
  const companyAuth = useMemo(() => accessToken, [accessToken]);
  const hasSettings =
    companyPermissions?.permissions.includes("core_manage_company_settings") ||
    companyPermissions?.permissions.includes("*");
  const hasBenefits = useMemo(() => {
    return checkSectionPermission("flash_benefits");
  }, [permissions]);
  const { expensesContractsData } = useExpensesContracts();
  const { isCorporateCardUser } = useCheckCorporateCardUser();

  const hasTimeAndAttendance = peopleContractModules?.modules?.includes(
    "time-and-attendance"
  );

  const { folhaCertaUser } = useFolhaCertaUser(hasTimeAndAttendance);

  let menuGroups = getMenuGroups(
    unleashToggles,
    companyPermissions?.permissions || [],
    isAdmin,
    hasSettings,
    isLoading,
    folhaCertaUser,
    peopleContractModules || null,
    expensesContractsData,
    isCorporateCardUser
  );
  const isMobile = useMediaQuery("(max-width: 768px)");

  if (
    !hasBenefits ||
    checkUnleashToggle("FLASH_OS_BLOCKED_TO_BENEFITS_SECTION", unleashToggles)
  )
    menuGroups = menuGroups.filter((group) => group.id !== "flash_benefits");

  if (checkUnleashToggle("FLASH_OS_BLOCKED_TO_MY_FLASH", unleashToggles)) {
    const index = menuGroups[0].menus.findIndex(
      (menuItem) => menuItem.name === "myFlash.title"
    );
    menuGroups[0].menus.splice(index, 1);
  }

  useEffect(() => {
    (async () => {
      await getCompany({ dispatch });
    })();
  }, [companyAuth]);

  useEffect(() => {
    const checkPeopleContractModules = async ({
      detail,
    }: CustomEvent<Company>) => {
      const { contractStatus } = await getPeopleContractModules(detail?.id);
      useAuth.setState({ contractStatus });
    };

    window.addEventListener(
      "header_company_selected",
      checkPeopleContractModules
    );

    () =>
      window.removeEventListener(
        "header_company_selected",
        checkPeopleContractModules
      );
  }, []);

  const MenuByDevice = useMemo(
    () =>
      isMobile ? (
        <NotificationsMenuProvider menuGroups={menuGroups}>
          <Mobile menuGroups={menuGroups} hasSettings={hasSettings} />
        </NotificationsMenuProvider>
      ) : (
        <NotificationsMenuProvider menuGroups={menuGroups}>
          <Desktop menuGroups={menuGroups} hasSettings={hasSettings} />
        </NotificationsMenuProvider>
      ),
    [
      isMobile,
      menuGroups,
      hasSettings,
      companyPermissions?.permissions,
      JSON.stringify(expensesContractsData),
      isCorporateCardUser,
    ]
  );

  return MenuByDevice;
};

export default Menu;
