import { useState, useMemo, useCallback, useContext } from "react";
import { Skeleton } from "@mui/material";
import { MenuItem, Group, SectionLoading } from "../..";
import { MenuGroupsProps, MenuProps, Notifications } from "../types";
import { Context } from "../../../context";
import {
  Container,
  LogoContainer,
  Logo,
  TopContainer,
  ArrowButton,
  MenusContainer,
  FooterContainer,
  ItemsContainer,
} from "./styles";
import LogoFlash from "../../../assets/logo_flash.svg";

interface DesktopProps {
  menuGroups: MenuGroupsProps[];
  hasSettings: boolean;
}

const Desktop = ({ menuGroups, hasSettings }: DesktopProps) => {
  const { company } = useContext(Context);
  const [menuOpen, setMenuOpen] = useState(true);

  const handleMenuOpen = useCallback(() => {
    setMenuOpen(!menuOpen);
  }, [menuOpen]);

  const commonGroups: MenuProps[] = useMemo(() => {
    const filteredMenus: MenuGroupsProps[] = menuGroups.filter(
      (mg) => mg?.menus?.length && !mg?.name
    );
    let menus: MenuProps[] = [];

    filteredMenus.map((fm) => {
      menus = [...menus, ...fm.menus];
    });

    return menus;
  }, [menuGroups]);

  const groups: MenuGroupsProps[] = useMemo(() => {
    return menuGroups.filter((mg) => mg?.menus?.length && mg?.name);
  }, [menuGroups]);

  const Groups = useMemo(() => {
    return groups.map((group) => {
      return (
        <Group
          key={group.id}
          id={group.id}
          name={group.name}
          menus={group.menus}
          sideBarOpen={menuOpen}
          onClick={handleMenuOpen}
        />
      );
    });
  }, [menuOpen, groups]);

  return (
    <Container active={menuOpen}>
      <TopContainer active={menuOpen}>
        <LogoContainer>
          <SectionLoading url={"getCompany"}>
            {({ loading }) =>
              loading ? (
                <Skeleton width="100%" height="100%" />
              ) : company?.logo ? (
                <Logo
                  active={menuOpen}
                  src={company.logo.path}
                  alt="logo"
                  loading="lazy"
                />
              ) : (
                <LogoFlash />
              )
            }
          </SectionLoading>
        </LogoContainer>
        <ArrowButton
          active={menuOpen}
          size="small"
          variant="line"
          icon={menuOpen ? "IconChevronsLeft" : "IconChevronsRight"}
          onClick={() => setMenuOpen(!menuOpen)}
        />
      </TopContainer>
      <MenusContainer>
        <ul>
          <ItemsContainer>
            {commonGroups.map((menu) => (
              <MenuItem
                key={menu.name}
                sideBarOpen={menuOpen}
                icon={menu.icon}
                to={menu.route}
                name={menu.name}
                isNew={menu.isNew}
                subMenus={menu.subMenus}
                isLoading={menu.isLoading}
                onClick={handleMenuOpen}
              />
            ))}
          </ItemsContainer>
          {Groups}
        </ul>
      </MenusContainer>
      {hasSettings && (
        <FooterContainer>
          <ul>
            <MenuItem
              sideBarOpen={menuOpen}
              icon={"IconSettings"}
              to={"/settings"}
              name={"settings"}
            />
          </ul>
        </FooterContainer>
      )}
    </Container>
  );
};

export default Desktop;
