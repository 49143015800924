import styled from "styled-components";
import { Typography, Icons } from "@flash-tecnologia/hros-web-ui-v2";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral.light.light1};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.light.light1};
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 70%;
  justify-content: flex-start;
  padding: ${({ theme }) => `${theme.spacings.xs} ${theme.spacings.xs}`};
  text-decoration: none;
  cursor: pointer;
  user-select: none;
`;

const IconContainer = styled.div<{ open: boolean }>`
  display: flex;
  margin-left: 6px;
  transform: ${({ open }) => (!open ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.2s ease;
`;

const StyledIcon = styled(Icons)<{ primary: boolean }>`
  svg {
    fill: transparent;
    stroke: ${({ theme, primary }) =>
      primary
        ? theme.colors.neutral.dark.dark5
        : theme.colors.neutral.light.light4};
  }
`;

const Title = styled(Typography)`
  display: block;
  display: -webkit-box;
  transition: opacity 0.3s ease-in;
  overflow: hidden;
  flex: 1;
  margin: 0px 1px;

  && {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral.light.light4};
    line-height: 1.2;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
`;

const MenusContainer = styled.div<{ showMenus: boolean }>`
  opacity: ${({ showMenus }) => (showMenus ? 1 : 0)};
  transition: opacity 0.3s ease;
  margin-bottom: ${({ theme, showMenus }) =>
    showMenus ? theme.spacings.xs : 0};
`;

export {
  ItemWrapper,
  ItemContainer,
  IconContainer,
  StyledIcon,
  Title,
  Container,
  MenusContainer,
};
