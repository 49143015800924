import { NavLink } from "react-router-dom";
import {
  StyledCollapsedContainer,
  StyledContainer,
  StyledHorizontalContainer,
  StyledIcon,
  StyledProductContainer,
  StyledProductName,
  StyledTitle,
} from "./styles";

interface NewProductsProps {
  products: {
    name: string;
    to: string;
  }[];
  menuCollapsed?: boolean;
}
const NewProducts = ({ products, menuCollapsed }: NewProductsProps) => {
  if (!products.length) return null;

  if (menuCollapsed)
    return (
      <StyledCollapsedContainer>
        <StyledIcon name={"IconStars"} size={16} />
      </StyledCollapsedContainer>
    );
  return (
    <StyledContainer>
      <StyledHorizontalContainer>
        <StyledTitle variant="body4"> Explore novos produtos</StyledTitle>
        <StyledIcon name={"IconStars"} size={16} />
      </StyledHorizontalContainer>
      {products.map((product) => {
        return (
          <StyledProductContainer>
            <NavLink to={product.to}>
              <StyledProductName variant="body4">
                {product.name}
              </StyledProductName>
            </NavLink>
          </StyledProductContainer>
        );
      })}
    </StyledContainer>
  );
};

export default NewProducts;
