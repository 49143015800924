import { useFlag } from "@flash-tecnologia/feature-flags";
import { getFromLS, setInLS } from "@flash-tecnologia/hros-web-utility";

const USER_PREFERENCES_KEY = "userPreferences";

export const useMenuConfig = () => {
  const showV1Banner = useFlag({
    flagName: "FLASH_OS_SHOW_MENU_BANNER",
  });

  const getCurrentVersion = () => {
    const userPreferences = getFromLS(USER_PREFERENCES_KEY);
    const defaultMenuVersion = showV1Banner ? 1 : 2;
    return userPreferences?.menuVersion || defaultMenuVersion;
  };
  const setCurrentVersion = (version: number) => {
    const userPreferences = getFromLS(USER_PREFERENCES_KEY) || {};
    userPreferences.menuVersion = version;
    setInLS({ key: USER_PREFERENCES_KEY, value: userPreferences });
  };

  return {
    getCurrentVersion,
    setCurrentVersion,
  };
};
