import { useState, useMemo } from "react";
import { MenuItem } from "./..";
import { MenuGroupsProps } from "../Menu/types";
import {
  Container,
  ItemWrapper,
  ItemContainer,
  IconContainer,
  StyledIcon,
  Title,
  MenusContainer,
} from "./styles";
import { useTranslation } from "react-i18next";
import { useNotificationsMenuContext } from "../../context/notification";

interface GroupProps extends MenuGroupsProps {
  sideBarOpen: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const Group = ({ name, menus, sideBarOpen, onClick }: GroupProps) => {
  const [open, setOpen] = useState<boolean>(true);
  const [translations] = useTranslation("translations");
  const menuName = translations(`menu.${name}`);
  const { notifications } = useNotificationsMenuContext();

  const groupName = useMemo(() => {
    const splittedName = menuName.split(" ");
    const lastIndex = splittedName.length ? splittedName.length - 1 : 0;

    return splittedName[lastIndex];
  }, [menuName]);

  const showMenus = useMemo(() => open || !sideBarOpen, [open, sideBarOpen]);

  return (
    <Container>
      <ItemWrapper>
        <ItemContainer $active={sideBarOpen} onClick={() => setOpen(!open)}>
          <Title variant="caption" $active={sideBarOpen}>
            {sideBarOpen ? menuName : groupName}
          </Title>
          <IconContainer $active={sideBarOpen} open={open}>
            <StyledIcon name="IconChevronDown" size={16} primary={false} />
          </IconContainer>
        </ItemContainer>
      </ItemWrapper>
      <MenusContainer showMenus={showMenus}>
        {showMenus &&
          menus.map((menu) => {
            return (
              <MenuItem
                key={menu.name}
                sideBarOpen={sideBarOpen}
                icon={menu.icon}
                to={menu.route}
                name={menu.name}
                isNew={menu.isNew}
                subMenus={menu.subMenus}
                isLoading={menu.isLoading}
                notification={notifications?.[menu.notificationKey] ?? null}
                onClick={onClick}
                onClickMenuItem={menu.onClick}
              />
            );
          })}
      </MenusContainer>
    </Container>
  );
};

export default Group;
