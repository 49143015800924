import { useState, useCallback, useContext } from "react";
import { Skeleton } from "@mui/material";
import {
  MenusWithDividers,
  NewProduct,
  Notifications,
  SelectedProfile,
} from "../types";
import { Context } from "../../../context";
import {
  Container,
  LogoContainer,
  Logo,
  TopContainer,
  ArrowButton,
  MenusContainer,
  ProfileToggleContainer,
  MenuGroupDivider,
  InnerContainer,
} from "./styles";
import LogoFlash from "../../../assets/logo_flash.svg";
import { SectionLoading } from "../../Common";
import MenuItem from "./MenuItem";
import ProfileToggle from "./ProfileToggle";
import Group from "./Group";
import NewProducts from "./NewProducts";

interface DesktopProps {
  menuGroups: { menus: MenusWithDividers; newProducts: NewProduct[] };
  notifications: Notifications;
  selectedProfile: SelectedProfile;
  onSelectProfile: (selectedProfile: SelectedProfile) => void;
  hasAdminProfile: boolean;
}

const Desktop = ({
  menuGroups,
  notifications,
  selectedProfile,
  onSelectProfile,
  hasAdminProfile,
}: DesktopProps) => {
  const { company } = useContext(Context);
  const [menuOpen, setMenuOpen] = useState(true);
  const [preferredMenuType, setPreferredMenuType] = useState<
    "collapsed" | "expanded"
  >("expanded");
  const [profileToggleExpanded, setProfileToggleExpanded] = useState(false);

  const preferredIsExpanded = preferredMenuType === "expanded";
  let isFirstCollapsibleMenuAlreadyTagged = false;

  const handleProfileToggleExpand = () => {
    setProfileToggleExpanded((prevState) => !prevState);
  };

  const handleSelectProfile = (profile: SelectedProfile) => {
    onSelectProfile(profile);
    setProfileToggleExpanded(false);
  };

  const handleMenuOpen = useCallback(() => {
    setMenuOpen(!menuOpen);
  }, [menuOpen]);

  const handleHoverIn = () => {
    setMenuOpen(true);
  };

  const handleHoverOut = () => {
    if (!preferredIsExpanded) {
      setProfileToggleExpanded(false);
      setMenuOpen(false);
    }
  };

  const handleArrowButtonClick = () => {
    if (preferredIsExpanded) {
      setPreferredMenuType("collapsed");
      setProfileToggleExpanded(false);
      setMenuOpen(false);
      return;
    }
    setPreferredMenuType("expanded");
  };

  return (
    <Container>
      <InnerContainer
        active={menuOpen}
        expanded={preferredIsExpanded}
        onMouseEnter={handleHoverIn}
        onMouseLeave={handleHoverOut}
      >
        <div>
          <TopContainer active={menuOpen}>
            <LogoContainer>
              <SectionLoading url={"getCompany"}>
                {({ loading }) =>
                  loading ? (
                    <Skeleton width="100%" height="100%" />
                  ) : company?.logo ? (
                    <Logo
                      active={menuOpen}
                      src={company.logo.path}
                      alt="logo"
                      loading="lazy"
                    />
                  ) : (
                    <LogoFlash />
                  )
                }
              </SectionLoading>
            </LogoContainer>
            {menuOpen && (
              <ArrowButton
                active={menuOpen}
                size="small"
                variant="line"
                color="var(--color-secondary-50)"
                icon={
                  preferredIsExpanded ? "IconChevronLeft" : "IconChevronRight"
                }
                onClick={handleArrowButtonClick}
              />
            )}
          </TopContainer>
          <ProfileToggleContainer>
            <ProfileToggle
              onHeaderClick={handleProfileToggleExpand}
              isExpanded={profileToggleExpanded}
              selectedProfile={selectedProfile}
              onSelectProfile={handleSelectProfile}
              menuOpen={menuOpen}
              hasAdminProfile={hasAdminProfile}
            />
          </ProfileToggleContainer>
        </div>
        <MenusContainer className="onboarding-menu-container">
          {menuGroups.menus.map((item) => {
            if (typeof item === "string") {
              return (
                <MenuGroupDivider
                  marginBottom={item === "bottomDivider"}
                  marginTop={item === "topDivider"}
                />
              );
            }
            if (!!("menus" in item)) {
              const GroupComponent = (
                <Group
                  key={item.id}
                  className={
                    !isFirstCollapsibleMenuAlreadyTagged
                      ? "onboarding-first-collapsible-product-menu"
                      : ""
                  }
                  name={item.name}
                  menus={item.menus}
                  notifications={notifications}
                  sideBarOpen={menuOpen}
                  onClick={handleMenuOpen}
                />
              );
              isFirstCollapsibleMenuAlreadyTagged = true;
              return GroupComponent;
            }
            return (
              <MenuItem
                {...(!!item.id && {
                  className: `onboarding-menu-${item.id}`,
                })}
                key={item.name}
                sideBarOpen={menuOpen}
                icon={item.icon}
                to={item.route}
                name={item.name}
                isNew={item.isNew}
                subMenus={item.subMenus}
                isLoading={item.isLoading}
                onClick={handleMenuOpen}
              />
            );
          })}
          <NewProducts
            products={menuGroups.newProducts}
            menuCollapsed={!menuOpen}
          />
        </MenusContainer>
      </InnerContainer>
    </Container>
  );
};

export default Desktop;
