import { useState, useMemo } from "react";
import {
  Container,
  ItemContainer,
  IconContainer,
  StyledIcon,
  Title,
  MenusContainer,
} from "./styles";
import { useTranslation } from "react-i18next";
import { MenuGroupsProps, Notifications } from "../../types";
import MenuItem from "../MenuItem";

interface GroupProps extends MenuGroupsProps {
  className?: string;
  sideBarOpen: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  notifications: Notifications;
}

const Group = ({
  className,
  name,
  menus,
  sideBarOpen,
  notifications,
  onClick,
}: GroupProps) => {
  const [open, setOpen] = useState<boolean>(true);
  const [translations] = useTranslation("translations");
  const menuName = translations(`menu.${name}`);

  const groupName = useMemo(() => {
    const splittedName = menuName.split(" ");
    const lastIndex = splittedName.length ? splittedName.length - 1 : 0;

    return splittedName[lastIndex];
  }, [menuName]);

  const showMenus = useMemo(() => open || !sideBarOpen, [open, sideBarOpen]);

  return (
    <Container className={className}>
      <ItemContainer $active={sideBarOpen} onClick={() => setOpen(!open)}>
        <Title variant="body4" $active={sideBarOpen}>
          {sideBarOpen ? menuName : groupName}
        </Title>
        <IconContainer $active={sideBarOpen} open={open}>
          <StyledIcon name="IconCaretDown" size={16} primary={false} />
        </IconContainer>
      </ItemContainer>
      <MenusContainer showMenus={showMenus}>
        {showMenus &&
          menus.map((menu) => {
            return (
              <MenuItem
                key={menu.name}
                sideBarOpen={sideBarOpen}
                icon={menu.icon}
                to={menu.route}
                name={menu.name}
                isNew={menu.isNew}
                subMenus={menu.subMenus}
                isLoading={menu.isLoading}
                notification={notifications?.[menu.notificationKey] ?? null}
                onClick={onClick}
                onClickMenuItem={menu.onClick}
              />
            );
          })}
      </MenusContainer>
    </Container>
  );
};

export default Group;
