import {
  getFromLS,
  setInLS,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";
import { useEffect, useMemo } from "react";
import useSWR from "swr";
import { getNotificationsMenus } from "../api";
import {
  MenuGroupsProps,
  MenuProps,
  Notifications,
} from "../components/Menu/types";

const NOTIFICATIONS_API_ROUTES_KEY = "notificationsApiRoutes";

export function useNotificationsMenu(
  menuGroups?: MenuGroupsProps[],
  refreshInterval: number = 300000 // 5 minutes
) {
  const { selectedCompany } = useSelectedCompany();
  const dataServices = useMemo(
    () => getServiceDataForNotifications(menuGroups),
    [JSON.stringify(menuGroups), selectedCompany.id]
  );

  const { data: notifications, isLoading } = useSWR<Notifications>(
    `notifications-${selectedCompany.id}-${JSON.stringify(dataServices)}`,
    () => getNotificationsMenus(selectedCompany.id, dataServices),
    {
      errorRetryCount: 3,
      refreshInterval,
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    !isLoading && setInLS({ key: NOTIFICATIONS_API_ROUTES_KEY, value: null });
  }, [JSON.stringify(notifications)]);

  return notifications;
}

function getServiceDataForNotifications(menuGroups: MenuGroupsProps[]) {
  const routes: {
    route: string;
    serviceName: MenuProps["notificationServiceName"];
  }[] = getFromLS(NOTIFICATIONS_API_ROUTES_KEY) ?? [];

  if (!routes || !routes?.length) {
    menuGroups?.forEach((group) =>
      group.menus?.forEach((menuItem) => {
        if (
          !routes.some(
            (data) =>
              data.route === menuItem.notificationRoute &&
              data.serviceName === menuItem.notificationServiceName
          )
        )
          menuItem.notificationRoute &&
            routes.push({
              route: menuItem.notificationRoute,
              serviceName: menuItem.notificationServiceName,
            });
      })
    );

    setInLS({ key: NOTIFICATIONS_API_ROUTES_KEY, value: routes });
  }

  return routes;
}
