import {
  segmentEventTracking,
  segmentPageTracking,
} from "@flash-tecnologia/hros-web-utility";

interface TrackEventProps {
  name: string;
  params?: Record<string, string>;
  module: string;
  businessUnit: string;
}

export const segmentEventTrack: (props: TrackEventProps) => void =
  segmentEventTracking;

export const segmentPageTrack: (props: TrackEventProps) => void =
  segmentPageTracking;
