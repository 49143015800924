import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const StyledListItem = styled.li`
  list-style: none;
  padding-bottom: ${({ theme }) => `${theme.spacings.xs5}`};
`;

export const ItemContainer = styled(NavLink)`
  padding: ${({ theme }) =>
    `${theme.spacings.xs4} 0 ${theme.spacings.xs4} ${theme.spacings.xs5}`};
  display: flex;
  align-items: center;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  border-radius: ${({ theme }) => theme.spacings.xs4};
  border: 1px solid transparent;

  &:hover {
    p {
      color: ${({ theme }) => theme.colors.secondary.dark.dark5};
    }
    svg {
      stroke: ${({ theme }) => theme.colors.secondary.dark.dark5};
    }
  }

  &.active {
    background-color: ${({ theme }) => theme.colors.secondary[99]};
    border: ${({ theme }) => `1px solid ${theme.colors.secondary[95]}`};
    p {
      color: ${({ theme }) => theme.colors.secondary.dark.dark5};
    }

    svg {
      stroke: ${({ theme }) => theme.colors.secondary.dark.dark5} !important;
    }
  }
`;

export const Title = styled(Typography)<{ $sideBarOpen: boolean }>`
  padding-left: ${({ theme }) => theme.spacings.xs5};
  display: block;
  display: -webkit-box;
  opacity: ${({ $sideBarOpen }) => ($sideBarOpen ? 1 : 0)};
  transition: opacity 0.3s ease-in;
  overflow: hidden;
  justify-content: flex-start;
  flex: 1;
  margin: 0px 1px;
  color: ${({ theme }) => theme.colors.neutral[50]};

  ${({ $sideBarOpen }) =>
    !$sideBarOpen &&
    `
  width: 0;
  height: 0;
  flex: 0;
  `}

  && {
    font-weight: 600;
    line-height: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
`;

export const NewTag = styled.div`
  padding: ${({ theme }) => `${theme.spacings.xs5} ${theme.spacings.xs3}`};
  border-radius: ${({ theme }) => theme.borders.radius.xl};
  background-color: ${({ theme }) => theme.colors.secondary[95]};
`;

export const NewTagText = styled(Typography)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs5};

  && {
    font-size: 10px;
    line-height: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.secondary[50]};
  }
`;
