import { IconTypes } from "@flash-tecnologia/hros-web-ui-v2";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation } from "react-router-dom";
import { SubMenuItemMobile } from "../.";
import { NotificationItem, SubMenuProps } from "../Menu/types";
import {
  FlexCenter,
  FlexColumn,
  IconContainer,
  ItemSkeleton,
  ItemWrapper,
  MenuContainer,
  NewTag,
  NewTagText,
  StyledIcon,
  StyledLink,
  StyledListItem,
  StyledSubMenuUl,
  SubMenus,
  Title,
} from "./styles";

interface Item {
  icon: IconTypes;
  to: string;
  name: string;
  subMenus?: SubMenuProps[];
  isNew?: boolean;
  firstItem?: boolean;
  groupName?: string;
  isLoading?: boolean;
  notification?: NotificationItem;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  onClickMenuItem?: () => void;
}

const MenuItemMobile = ({
  name,
  icon,
  subMenus,
  to,
  isNew,
  firstItem,
  isLoading,
  notification,
  onClick,
  onClickMenuItem,
}: Item) => {
  const location = useLocation();
  const [translations, i18n] = useTranslation("translations");

  useEffect(() => {
    i18n.changeLanguage("pt-BR");
  }, []);

  const isActive = useMemo(() => {
    return subMenus?.length
      ? subMenus.some(({ route }) =>
          matchPath({ path: route, end: false }, location.pathname)
        )
      : Boolean(to && matchPath({ path: to, end: false }, location.pathname));
  }, [to, location]);

  const isExpandable = useMemo(
    () => Boolean(subMenus && subMenus.length),
    [subMenus]
  );

  const isSubMenuActive: boolean = useMemo(
    () =>
      isExpandable
        ? subMenus.some((subMenu) => {
            return Boolean(
              matchPath({ path: subMenu.route, end: false }, location.pathname)
            );
          })
        : false,
    [isExpandable, location?.pathname, subMenus]
  );

  const [open, setOpen] = useState<boolean>(isSubMenuActive);

  const ItemContainer: any = useMemo(
    () => (isExpandable ? MenuContainer : StyledLink),
    [isExpandable]
  );

  const SubMenuItemsMobile = useMemo(() => {
    return subMenus?.map((subMenu) => (
      <SubMenuItemMobile
        key={subMenu.name}
        name={translations(`menu.${subMenu.name}`)}
        route={subMenu.route}
        onClick={onClick}
        notificationKey={subMenu.notificationKey}
      />
    ));
  }, [subMenus, subMenus?.length]);

  const handleOnClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (isExpandable) setOpen(!open);
      else onClick(e);

      if (onClickMenuItem) {
        onClickMenuItem();
      }
    },
    [open, isExpandable, onClick]
  );

  return (
    <StyledListItem firstItem={firstItem}>
      <ItemWrapper>
        <FlexColumn>
          {isLoading ? (
            <ItemSkeleton variant="text" />
          ) : (
            <ItemContainer to={to} $active={isActive} onClick={handleOnClick}>
              <StyledIcon name={icon} size={16} primary />
              <FlexCenter>
                <Title variant="body4">{translations(`menu.${name}`)}</Title>

                {isNew && (
                  <NewTag>
                    <NewTagText variant="caption" tag="p">
                      {translations("menuItem.newItem")}
                    </NewTagText>
                  </NewTag>
                )}

                {Boolean(notification?.value) && (
                  <NewTag>
                    <NewTagText variant="caption" tag="p">
                      {notification.value}
                    </NewTagText>
                  </NewTag>
                )}
              </FlexCenter>

              {isExpandable && (
                <IconContainer open={open}>
                  <StyledIcon
                    name="IconChevronDown"
                    size={16}
                    primary={false}
                  />
                </IconContainer>
              )}
            </ItemContainer>
          )}

          <SubMenus $active={isExpandable && open}>
            {isExpandable && open && (
              <StyledSubMenuUl>{SubMenuItemsMobile}</StyledSubMenuUl>
            )}
          </SubMenus>
        </FlexColumn>
      </ItemWrapper>
    </StyledListItem>
  );
};

export default MenuItemMobile;
