import { useNotificationsMenuContext } from "../../context/notification";
import { SubMenuProps } from "../Menu/types";
import { NewTagText, NotificationDescription } from "../MenuItem/styles";
import { NewTag } from "../MenuV2/Desktop/MenuItem/styles";

import { ItemContainer, StyledListItem, Title } from "./styles";

interface SubMenuItemProps extends SubMenuProps {
  sideBarOpen: boolean;
}

const SubMenuItem = ({
  name,
  route,
  sideBarOpen,
  notificationKey,
  onClick,
}: SubMenuItemProps) => {
  const { notifications } = useNotificationsMenuContext();
  const notification = notifications?.[notificationKey];

  return (
    <StyledListItem onClick={onClick}>
      <ItemContainer to={route}>
        <Title variant="body4" $sideBarOpen={sideBarOpen}>
          {name}
        </Title>
      </ItemContainer>
      {Boolean(notification?.value) && (
        <NewTag>
          <NewTagText variant="caption" tag="p">
            <NotificationDescription sideBarOpen={sideBarOpen}>
              {notification.value}
            </NotificationDescription>
          </NewTagText>
        </NewTag>
      )}
    </StyledListItem>
  );
};

export default SubMenuItem;
