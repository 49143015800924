import { useMemo, useState, useCallback } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { IconTypes } from "@flash-tecnologia/hros-web-ui-v2";
import { SubMenuItem } from "../.";
import { NotificationItem, SubMenuProps } from "../Menu/types";
import {
  StyledListItem,
  StyledIcon,
  Title,
  ItemWrapper,
  StyledLink,
  StyledRedirectLink,
  IconContainer,
  StyledSubMenuUl,
  MenuContainer,
  SubMenus,
  FlexColumn,
  NewTag,
  NewTagText,
  FlexCenter,
  NewItemIcon,
  ItemSkeleton,
  NotificationDescription,
  FlaggedActive,
} from "./styles";
import { useTranslation } from "react-i18next";

interface Item {
  sideBarOpen: boolean;
  icon: IconTypes;
  to: string;
  name: string;
  subMenus?: SubMenuProps[];
  isNew?: boolean;
  isLoading?: boolean;
  notification?: NotificationItem;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onClickMenuItem?: () => void;
}

const MenuItem = ({
  sideBarOpen,
  name,
  icon,
  subMenus,
  to,
  notification,
  isNew,
  isLoading,
  onClick,
  onClickMenuItem,
}: Item) => {
  const location = useLocation();
  const [translations] = useTranslation("translations");

  const isActive = useMemo(() => {
    return subMenus?.length
      ? subMenus.some(({ route }) =>
          matchPath({ path: route, end: false }, location.pathname)
        )
      : Boolean(to && matchPath({ path: to, end: false }, location.pathname));
  }, [to, location]);

  const isExpandable = useMemo(
    () => Boolean(subMenus && subMenus.length),
    [subMenus]
  );

  const isSubMenuActive: boolean = useMemo(
    () =>
      isExpandable
        ? subMenus.some((subMenu) => {
            return Boolean(
              matchPath({ path: subMenu.route, end: false }, location.pathname)
            );
          })
        : false,
    [isExpandable, location?.pathname, subMenus]
  );

  const [open, setOpen] = useState<boolean>(isSubMenuActive);

  const ItemContainer: any = useMemo(() => {
    if (isExpandable) return MenuContainer;

    const isRedirectLink = to.includes("https://");
    return isRedirectLink ? StyledRedirectLink : StyledLink;
  }, [isExpandable]);

  const SubMenuItems = useMemo(() => {
    return subMenus?.map((subMenu) => (
      <SubMenuItem
        key={subMenu.name}
        name={translations(`menu.${subMenu.name}`)}
        route={subMenu.route}
        onClick={subMenu?.onClick}
        sideBarOpen={sideBarOpen}
        notificationKey={subMenu.notificationKey}
      />
    ));
  }, [sideBarOpen, isLoading, subMenus, subMenus?.length]);

  const showExpandable = useMemo(
    () => Boolean(sideBarOpen && isExpandable),
    [sideBarOpen, isExpandable]
  );

  const setSubMenuOpen = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (isExpandable && !sideBarOpen) {
        onClick && onClick(e);
        !open && setOpen(!open);
        return;
      }

      setOpen(!open);
    },
    [open, isExpandable, sideBarOpen, onClick]
  );

  const handleMenuClick = (e: React.MouseEvent<HTMLElement>) => {
    setSubMenuOpen(e);

    if (onClickMenuItem) {
      onClickMenuItem();
    }
  };

  return (
    <StyledListItem>
      {isActive && <FlaggedActive />}
      <ItemWrapper>
        <FlexColumn sideBarOpen={sideBarOpen}>
          {isLoading ? (
            <ItemSkeleton variant="text" />
          ) : (
            <ItemContainer
              to={to}
              href={to}
              target={to?.includes("https://") ? "_blank" : "_self"}
              $sideBarOpen={sideBarOpen}
              $active={
                isActive || (!sideBarOpen && Boolean(notification?.value))
              }
              onClick={handleMenuClick}
              $hasNotification={Boolean(notification?.value)}
            >
              <div style={{ position: "relative" }}>
                {sideBarOpen ? (
                  <StyledIcon
                    name={icon}
                    size={16}
                    primary
                    sideBarOpen={sideBarOpen}
                  />
                ) : Boolean(notification?.value) ? (
                  <NewTag>
                    <NewTagText variant="caption" tag="p">
                      <StyledIcon
                        name={icon}
                        size={16}
                        primary
                        hasNotification={Boolean(notification?.value)}
                        sideBarOpen={sideBarOpen}
                      />
                      <NotificationDescription>
                        {notification?.value}
                      </NotificationDescription>
                    </NewTagText>
                  </NewTag>
                ) : (
                  <StyledIcon
                    name={icon}
                    size={16}
                    primary
                    hasNotification={Boolean(notification?.value)}
                    sideBarOpen={sideBarOpen}
                  />
                )}

                {isNew && (
                  <NewItemIcon
                    name="IconNorthStar"
                    size={8}
                    $sideBarOpen={sideBarOpen}
                  />
                )}
              </div>
              <FlexCenter $sideBarOpen={sideBarOpen}>
                <Title variant="body4" $sideBarOpen={sideBarOpen}>
                  {translations(`menu.${name}`)}
                </Title>
                {isNew && (
                  <NewTag>
                    <NewTagText variant="caption" tag="p">
                      {translations("menuItem.newItem")}
                    </NewTagText>
                  </NewTag>
                )}
                {Boolean(notification?.value) && (
                  <NewTag>
                    <NewTagText variant="caption" tag="p">
                      <NotificationDescription sideBarOpen={sideBarOpen}>
                        {notification.value}
                      </NotificationDescription>
                    </NewTagText>
                  </NewTag>
                )}
              </FlexCenter>
              {isExpandable && (
                <IconContainer open={open} $sideBarOpen={sideBarOpen}>
                  <StyledIcon
                    name="IconChevronDown"
                    size={16}
                    primary={false}
                  />
                </IconContainer>
              )}
            </ItemContainer>
          )}

          <SubMenus $active={showExpandable && open}>
            {showExpandable && open && !isLoading && (
              <StyledSubMenuUl>{SubMenuItems}</StyledSubMenuUl>
            )}
          </SubMenus>
        </FlexColumn>
      </ItemWrapper>
    </StyledListItem>
  );
};

export default MenuItem;
