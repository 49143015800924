import { IToggle } from "unleash-proxy-client";
import { usePermissionsFlags } from "@flash-hros/auth-helper";
/**
 * @deprecated Discontinued because it's not beeing updated when changes the company, please use checkUnleashToggle instead
 */
export function checkUnleashPermission(flagName: string) {
  const flags = usePermissionsFlags.getState().flags;
  return flags?.some((_flag) => _flag.name === flagName);
}
export function checkUnleashToggle(flagName: string, flagsUnleash: IToggle[]) {
  return flagsUnleash?.some((_flag) => _flag.name === flagName);
}
