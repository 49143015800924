import styled from "styled-components";
import { IconButton } from "@flash-tecnologia/hros-web-ui-v2";

const Container = styled.div<{ active: boolean }>`
  background: ${({ theme }) => theme.colors.neutral[100]};
  height: 100%;
  transition: width 0.2s ease-in;
  width: ${({ active }) => (active ? "240" : "112")}px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
`;

const TopContainer = styled.div<{ active: boolean }>`
  margin: ${({ active }) =>
    active ? "24px 6px 22px 24px" : "24px 28px 22px 28px"};
  display: flex;
  justify-content: ${({ active }) => (active ? "space-between" : "center")};
  flex-direction: ${({ active }) => (active ? "row" : "column")};
  align-items: center;
  transition: 0.3s ease-in;
`;

const LogoContainer = styled.div`
  height: 20px;
  width: 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Logo = styled.img<{ active: boolean }>`
  object-fit: contain;
  object-position: ${({ active }) => (active ? 0 : "50%")};
  width: 100%;
  height: 100%;
`;

const ArrowButton = styled(IconButton)<{ active: boolean }>`
  margin-top: ${({ active }) => (active ? "0px" : "20px")};
  transition: 0.3s ease-in;

  && {
    &.MuiButtonBase-root {
      width: 40px;
      height: 40px;
      aspect-ratio: auto;
      align-self: center;
    }
  }
`;

const MenusContainer = styled.div`
  position: relative;
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
`;

const ItemsContainer = styled.div`
  transition: opacity 0.3s ease;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs4};
  margin-bottom: ${({ theme }) => theme.spacings.xs};
`;

const FooterContainer = styled.div`
  padding: 20px 0 4px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral.light.light1};
`;

export {
  Container,
  TopContainer,
  LogoContainer,
  Logo,
  ArrowButton,
  MenusContainer,
  FooterContainer,
  ItemsContainer,
};
