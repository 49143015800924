import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import { Typography, Icons, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

const StyledListItem = styled.li<{ firstItem: boolean }>`
  list-style: none;
  margin-bottom: 24px;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  &:first-child {
    padding-top: ${({ firstItem }) => firstItem && "24px"};
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ItemSkeleton = styled(Skeleton)`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 10%;
  margin-bottom: -3%;
`;

const CommonContainer = css<{ $active: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-start;
  margin: ${({ theme }) => `0 ${theme.spacings.xs2} 0 ${theme.spacings.xs}`};
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  width: 49%;

  ${({ $active }) =>
    $active &&
    css`
      p {
        color: ${({ theme }) => theme.colors.secondary.dark.dark5};
      }

      svg {
        stroke: ${({ theme }) => theme.colors.secondary.dark.dark5} !important;
      }
    `}

  &:hover {
    p {
      color: ${({ theme }) => theme.colors.secondary.dark.dark5};
    }
    svg {
      stroke: ${({ theme }) => theme.colors.secondary.dark.dark5};
    }
  }
`;

const StyledLink = styled(NavLink)<{ $active: boolean }>`
  ${CommonContainer};
`;

const MenuContainer = styled.div<{ $active: boolean }>`
  ${CommonContainer};
`;

const IconContainer = styled.div<{
  open: boolean;
}>`
  display: flex;
  margin-left: 6px;
  transform: ${({ open }) => (open ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.2s ease, opacity 0.3s linear;
`;

const StyledIcon = styled(Icons)<{ primary: boolean }>`
  svg {
    fill: transparent;
    stroke: ${({ theme, primary }) =>
      primary
        ? theme.colors.neutral.dark.dark5
        : theme.colors.neutral.light.light4};
  }
`;

const Title = styled(Typography)`
  display: flex;
  overflow: hidden;
  justify-content: flex-start;
  margin: 0px 1px;
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};

  && {
    margin: 0px 0px 0px 6px;
    font-weight: 600;
    line-height: 1.2;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
`;

const SubMenus = styled.div<{ $active: boolean }>`
  opacity: ${({ $active }) => ($active ? 1 : 0)};
  transition: all 0.5s ease;
`;

const StyledSubMenuUl = styled.ul`
  margin: 20px 0px 8px 32px;
  border-left: 1px solid ${({ theme }) => theme.colors.neutral.light.light3};
`;

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  flex: 1;
`;

const NewTag = styled.div`
  padding: 4px 8px;
  border-radius: ${({ theme }) => theme.borders.radius.xl};
  background-color: ${({ theme }) => theme.colors.secondary[95]};
`;

const NewTagText = styled(Typography)`
  && {
    font-size: 10px;
    line-height: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.secondary[50]};
  }
`;

export {
  StyledListItem,
  StyledIcon,
  ItemWrapper,
  StyledLink,
  MenuContainer,
  Title,
  IconContainer,
  SubMenus,
  StyledSubMenuUl,
  FlexColumn,
  FlexCenter,
  NewTag,
  NewTagText,
  ItemSkeleton,
};
