export enum EExpenseContractType {
  FLASH_APP_SAAS_SERVICE_CONTRACT = "FLASH_APP_SAAS_SERVICE_CONTRACT",
  FLASH_APP_CONTRACT_CORPORATE_CARD = "FLASH_APP_CONTRACT_CORPORATE_CARD",
}

export enum EExpenseMenuIcon {
  corporateCard = "IconCreditCard",
  externalCards = "IconCreditCard",
  expenses = "IconReceipt2",
  advances = "IconCurrencyDollar",
  approvals = "IconCircleCheck",
  accounting = "IconCalculator",
  customizations = "IconAdjustmentsHorizontal",
  analytics = "IconChartPie",
  travel = "IconPlaneTilt",
  vendor = "IconBox",
}

export enum EExpenseMenuItem {
  corporateCard = "corporateCard",
  expenses = "expenses",
  vendor = "vendor",
  advances = "advances",
  travel = "travel",
  approvals = "approvals",
  accounting = "accounting",
  customizations = "customizations",
  analytics = "analytics",
}

export enum EExpensePermissions {
  expenses = "expense_view_expenses_reports",
  advances = "expense_view_advances_expenses_reports",
  approvals = "expense_view_reports_approval",
  accounting = "expense_view_financial_reports",
  customizations = "expense_view_customizations",
  analytics = "expense_view_analytics",
  contractSign = "expense_contract_sign",
}

export enum EExpenseNotificationsKey {
  expenses = "expensesViolationCount",
  advances = "advPaymentsOpenCount",
  approvals = "approvalCount",
  accounting = "financialApprovalCount",
  accountabilityAccount = "reportWaitingFinancialApprovalCount",
  advancesAccount = "advPaymentsWaitingFinancialApprovalCount",
}

export enum ECorporateCardNotificationsKey {
  manageBalance = "manageBalance",
}

export interface IExpensesContracts {
  companyId: string;
  contracts: EExpenseContractType[];
  features: string[];
  plan: string;
  isLoading: boolean;
  isDisabledExpense: boolean;
}
