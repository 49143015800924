import { useEffect, useState } from "react";
import { SelectedProfile } from "../components/MenuV2/types";
import { matchPath, useLocation } from "react-router-dom";
import { mapEmployeeMenuFromV1 } from "../components/MenuV2/MenuGroups/Employee/mapEmployeeMenuFromV1";
import { MenuProps } from "@flash-tecnologia/hros-web-ui-v2";

type MenuGroup = ReturnType<typeof mapEmployeeMenuFromV1>;

/**
 * This hook manages menu view changes from the admin route and profile.
 * @param {Boolean} hasAdminProfile if the user has administrator view
 * @param {MenuGroup} adminMenuGroups menu groups from admin
 * @param {MenuGroup} employeeMenuGroups  menu groups from employee
 */
export function useSelectedView(
  hasAdminProfile: boolean,
  adminMenuGroups: MenuGroup,
  employeeMenuGroups: MenuGroup
) {
  const { pathname } = useLocation();
  const [selectedProfile, setSelectedProfile] = useState<SelectedProfile>(
    hasAdminProfile ? "admin" : "employee"
  );

  function getCurrentRoute(menu): MenuProps | null {
    if (!menu.subMenus && !menu.menus) {
      return pathname && matchPath(menu.route, pathname) ? menu : null;
    }

    const menuToCheck = menu.subMenus || menu.menus;

    for (const menuItem of menuToCheck) {
      const selectedMenu = getCurrentRoute(menuItem);

      if (selectedMenu) {
        return selectedMenu;
      }
    }

    return null;
  }

  function getCurrentView() {
    let selectedRoute = hasAdminProfile
      ? getCurrentRoute(adminMenuGroups)
      : null;

    if (selectedRoute) {
      return "admin";
    }

    selectedRoute = getCurrentRoute(employeeMenuGroups);

    if (selectedRoute) {
      return "employee";
    }

    return hasAdminProfile ? "admin" : "employee";
  }

  function handleSelectedView(menu: SelectedProfile) {
    if (menu !== "admin" && menu !== "employee") {
      setSelectedProfile("employee");
      return;
    }

    setSelectedProfile(menu);
  }

  useEffect(() => {
    const currentView = getCurrentView();
    handleSelectedView(currentView);
  }, [hasAdminProfile]);

  return {
    selectedProfile,
    handleSelectedView,
  };
}
