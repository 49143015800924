import {
  MonitoringErrorBoundary,
  MonitoringManager,
} from "@flash-tecnologia/hros-web-utility";
import { MonitoringLevel } from "@flash-tecnologia/hros-web-utility/dist/monitoring/MonitoringLevel";

export const projectDsn =
  "https://2be7c5b796804f635408f3acbb359241@o266934.ingest.sentry.io/4505686531899392";

export class ErrorBoundary extends MonitoringErrorBoundary {
  project: string = projectDsn;

  constructor(props) {
    super(props);
  }

  static captureException(
    error: Error,
    severity?: MonitoringLevel,
    extras?: Record<string, any>
  ) {
    MonitoringManager.captureException(projectDsn, error, severity, extras);
  }
}
