import { segmentEventTrack } from "./segment";

export const setEventTracking = (
  name: string,
  businessUnit: string = "platform",
  params?: Record<string, string>
) => {
  segmentEventTrack({
    name,
    params,
    module: "menu",
    businessUnit,
  });
};

type ObjectLiteral = {
  [key: string]: string;
};

export const track = ({
  type,
  screen,
  businessUnit = "platform",
  params,
}: {
  type: string;
  screen: string;
  businessUnit: string;
  params?: ObjectLiteral;
}) => {
  segmentEventTrack({
    name: `${type}_${screen}`,
    module: "menu",
    businessUnit,
    params,
  });
};
