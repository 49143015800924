import Menu from "../Menu";
import MenuV2 from "../MenuV2";
import { useMenuConfig } from "../../hooks/use-menu-config";
import { useEffect } from "react";
import {
  AnalyticsManager,
  getAuthenticatedUser,
  usePermissions,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";

const MenuSelector = () => {
  const { selectedCompany } = useSelectedCompany();
  const { companies, isAdmin } = usePermissions();
  const { getCurrentVersion } = useMenuConfig();
  const isMenuV2 = getCurrentVersion() === 2;

  useEffect(() => {
    const updateIdentityMetadata = async () => {
      const user = await getAuthenticatedUser();
      const company = companies.find(
        (company) => company.id === selectedCompany.id
      );

      if (user && (isAdmin || !!company.permissions.length)) {
        AnalyticsManager.shared.identify(user.sub, { isMenuV2 });
      }
    };

    updateIdentityMetadata();
  }, [isMenuV2, isAdmin, companies, selectedCompany]);

  if (isMenuV2) {
    return <MenuV2 />;
  }
  return <Menu />;
};

export default MenuSelector;
