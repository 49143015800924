import React, { ReactNode, createContext, useContext } from "react";
import { MenuGroupsProps, Notifications } from "../components/Menu/types";
import { useNotificationsMenu } from "../hooks/useNotificationsMenu";

interface NotificationsMenuContextProps {
  notifications?: Notifications;
}

const NotificationsMenuContext = createContext<
  NotificationsMenuContextProps | undefined
>(undefined);

export const NotificationsMenuProvider: React.FC<{
  children: ReactNode;
  menuGroups?: MenuGroupsProps[];
  refreshInterval?: number;
}> = ({ children, menuGroups, refreshInterval }) => {
  const notifications = useNotificationsMenu(menuGroups, refreshInterval);

  return (
    <NotificationsMenuContext.Provider value={{ notifications }}>
      {children}
    </NotificationsMenuContext.Provider>
  );
};

export const useNotificationsMenuContext =
  (): NotificationsMenuContextProps => {
    const context = useContext(NotificationsMenuContext);
    if (!context) {
      throw new Error(
        "useNotificationsMenuContext must be used within a NotificationsMenuProvider"
      );
    }
    return context;
  };
