import { createContext, ReactElement, useReducer } from "react";
import { reducer, initialState } from "./reducer";

import { IContext } from "./types";

const Context = createContext<IContext>(undefined);

const ConfigurationContext = (props: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value: IContext = {
    dispatch,
    ...state,
  };

  return <Context.Provider value={value}>{props.children}</Context.Provider>;
};

export { ConfigurationContext, Context };
