import styled from "styled-components";
import { IconButton } from "@flash-tecnologia/hros-web-ui-v2";

const Container = styled.div`
  display: flex;
  padding: 12px 24px;
  background: ${({ theme }) => theme.colors.neutral.light.pure};
  width: 100%;
  align-items: center;
  position: relative;
  user-select: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.light.light2}; ;
`;

const LeftContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`;

const ArrowButton = styled(IconButton)`
  && {
    &.MuiButtonBase-root {
      width: 40px;
      height: 40px;
      aspect-ratio: auto;
      align-self: center;
    }
  }
`;

// export const Flex = styled.div`
//   display: flex;
// `;

const CenterContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  margin-left: 15px;
`;

const LogoContainer = styled.div`
  height: 20px;
  width: 63px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Logo = styled.img<{ $active: boolean }>`
  object-fit: contain;
  object-position: 0;
  width: 100%;
  height: 100%;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const OptionsButton = styled(IconButton)`
  && {
    &.MuiButtonBase-root {
      width: 32px;
      height: 32px;
      aspect-ratio: auto;
      align-self: center;
    }
  }
`;

const AbsoluteOverlay = styled.div<{
  menuHeight: number;
  $active: boolean;
}>`
  position: absolute;
  top: ${({ menuHeight }) => menuHeight}px;
  z-index: 997;
  width: 100%;
  height: ${({ menuHeight }) => `calc(100vh - ${menuHeight}px)`};
  background-color: ${({ $active }) => ($active ? "rgba(0, 0, 0, 0.8)" : "")};
  display: ${({ $active }) => ($active ? "flex" : "none")};
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`;

const MenuContainer = styled.div<{
  $active: boolean;
  menuHeight: number;
}>`
  position: absolute;
  left: ${({ $active }) => ($active ? "0px" : "-400px")};
  display: flex;
  width: 100%;
  max-width: 400px;
  top: ${({ menuHeight }) => menuHeight}px;
  height: ${({ menuHeight }) => `calc(100vh - ${menuHeight}px)`};
  background: ${({ theme }) => theme.colors.neutral.light.pure};
  flex-direction: column;
  z-index: 998;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  transition: left 0.4s ease-in;
`;

export const ScrolledMenus = styled.div`
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
`;

const FooterContainer = styled.div`
  padding: 20px 0 4px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral.light.light1};
`;

export {
  Container,
  LeftContainer,
  ArrowButton,
  CenterContainer,
  LogoContainer,
  Logo,
  RightContainer,
  OptionsButton,
  AbsoluteOverlay,
  MenuContainer,
  FooterContainer,
};
