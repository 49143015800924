enum EActions {
  GET_COMPANY = "GET_COMPANY",
  GET_CONTRACTS = "GET_CONTRACTS",
  SYSTEM_LOADING_START = "SYSTEM_LOADING_START",
  SYSTEM_LOADING_END = "SYSTEM_LOADING_END",
}

interface IActions {
  type: EActions;
  payload?: any;
}

export { IActions, EActions };
