import {
  useState,
  useCallback,
  useEffect,
  useRef,
  useContext,
  useMemo,
} from "react";
import { Skeleton } from "@mui/material";
import { default as ProfileDropdown } from "./ProfileDropdown";
import { SectionLoading, MenuItemMobile, GroupMobile } from "../..";
import { MenuGroupsProps, MenuProps, Notifications } from "../types";
import { Context } from "../../../context";
import {
  Container,
  LeftContainer,
  ArrowButton,
  CenterContainer,
  LogoContainer,
  Logo,
  RightContainer,
  AbsoluteOverlay,
  MenuContainer,
  ScrolledMenus,
  FooterContainer,
} from "./styles";
import LogoFlash from "../../../assets/logo_flash.svg";

interface MobileProps {
  menuGroups: MenuGroupsProps[];
  hasSettings: boolean;
}

const Mobile = ({ menuGroups, hasSettings }: MobileProps) => {
  const { company } = useContext(Context);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuContainerHeight, setMenuContainerHeight] = useState(0);
  const ref = useRef(null);
  const handleMenuOpen = useCallback(() => {
    setMenuOpen((prevMenuOpen) => !prevMenuOpen);
  }, []);

  const commonGroups: MenuProps[] = useMemo(() => {
    const filteredMenus: MenuGroupsProps[] = menuGroups.filter(
      (mg) => mg?.menus?.length && !mg?.name
    );
    let menus: MenuProps[] = [];

    filteredMenus.map((fm) => {
      menus = [...menus, ...fm.menus];
    });

    return menus;
  }, [menuGroups]);

  const groups: MenuGroupsProps[] = useMemo(() => {
    return menuGroups.filter((mg) => mg?.menus?.length && mg?.name);
  }, [menuGroups]);

  useEffect(() => {
    setMenuContainerHeight(ref.current.clientHeight);
  }, []);

  const Groups = useMemo(() => {
    return groups.map((group) => (
      <GroupMobile
        key={group.id}
        id={group.id}
        name={group.name}
        menus={group.menus}
        onClick={handleMenuOpen}
      />
    ));
  }, [groups]);

  return (
    <Container ref={ref}>
      <LeftContainer>
        <ArrowButton
          size="small"
          variant="line"
          icon={menuOpen ? "IconX" : "IconMenu2"}
          onClick={handleMenuOpen}
        />
      </LeftContainer>
      <CenterContainer>
        <LogoContainer>
          <SectionLoading url={"getCompany"}>
            {({ loading }) =>
              loading ? (
                <Skeleton width="100%" height="100%" />
              ) : company?.logo ? (
                <Logo
                  $active={menuOpen}
                  src={company.logo.path}
                  alt="logo"
                  loading="lazy"
                />
              ) : (
                <LogoFlash />
              )
            }
          </SectionLoading>
        </LogoContainer>
      </CenterContainer>
      <RightContainer>
        <ProfileDropdown />
      </RightContainer>
      <AbsoluteOverlay
        $active={menuOpen}
        menuHeight={menuContainerHeight}
        onClick={handleMenuOpen}
      />
      <MenuContainer
        $active={menuOpen}
        menuHeight={menuContainerHeight}
        onClick={(e) => e.stopPropagation()}
      >
        <ScrolledMenus>
          <ul>
            {commonGroups.map((menu, index) => (
              <MenuItemMobile
                firstItem={index === 0}
                key={menu.name}
                icon={menu.icon}
                to={menu.route}
                name={menu.name}
                isNew={menu.isNew}
                subMenus={menu.subMenus}
                onClick={handleMenuOpen}
              />
            ))}
            {Groups}
          </ul>
        </ScrolledMenus>
        {hasSettings && (
          <FooterContainer>
            <ul>
              <MenuItemMobile
                icon={"IconSettings"}
                to={"/settings"}
                name={"settings"}
                onClick={handleMenuOpen}
              />
            </ul>
          </FooterContainer>
        )}
      </MenuContainer>
    </Container>
  );
};

export default Mobile;
