import styled from "styled-components";
import { Menu, IconButton } from "@flash-tecnologia/hros-web-ui-v2";

const StyledMenu = styled(Menu)`
  && {
    &.menu-base-custom-theme {
      ul {
        &.MuiList-root {
          li {
            &.MuiButtonBase-root {
              div {
                text-align: start;
              }
            }
          }
        }
      }
    }
  }
`;

const OptionsButton = styled(IconButton)`
  && {
    &.MuiButtonBase-root {
      width: 32px;
      height: 32px;
      aspect-ratio: auto;
      align-self: center;
    }
  }
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export { StyledMenu, OptionsButton, StyledContainer };
