import { useEffect, useState } from "react";
import { getFolhaCertaPermissions, UsuarioFolhaCerta } from "../api";

export const useFolhaCertaUser = (hasTimeAndAttendance: boolean) => {
  const [folhaCertaUser, setFolhaCertaUser] =
    useState<UsuarioFolhaCerta | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getFolhaCertaPermissions(hasTimeAndAttendance);
      setFolhaCertaUser(result);
    };

    fetchData();
  }, [hasTimeAndAttendance]);

  return {
    folhaCertaUser,
  };
};
