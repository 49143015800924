import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Icons } from "@flash-tecnologia/hros-web-ui-v2";

import { getFromLS } from "@flash-tecnologia/hros-web-utility";

import { dispatchLogout } from "../../../../utils";

import { StyledMenu, OptionsButton, StyledContainer } from "./styles";

const ProfileDropdown = () => {
  const navigate = useNavigate();

  const accessToken = getFromLS("hrosAccessToken");

  const employeeId = useMemo(
    () => accessToken?.employeeId || "",
    [accessToken]
  );

  const [isOpenPopConfirm, setIsOpenPopConfirm] = useState(false);

  const employeeOptions = useMemo(
    () => [
      {
        onClick: () => {
          window.dispatchEvent(new CustomEvent("changeAccountMenu"));
        },
        children: (
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <StyledContainer>
              <Icons name="IconArrowsDiff" fill="none" width={16} height={16} />
              <Typography
                variant="body4"
                children="Acessar outra conta Flash"
                style={{ fontWeight: 700, width: "200px" }}
              />
            </StyledContainer>
          </div>
        ),
      },
      {
        onClick: () => {
          navigate(`/employees/person`);
        },
        children: (
          <div
            style={{
              display: "flex",
              alignContent: "center",
              borderRadius: "0",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <Icons name="IconUser" fill="none" width={16} height={16} />
              <Typography
                variant="body4"
                children="Minha Conta"
                style={{ fontWeight: 700, width: "200px" }}
              />
            </div>
          </div>
        ),
      },
      // {
      //   onClick: () => {
      //     navigate(`/employees/account`);
      //   },
      //   children: (
      //     <div
      //       style={{
      //         display: "flex",
      //         alignContent: "center",
      //         borderRadius: "0",
      //       }}
      //     >
      //       <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
      //         <Icons name="IconSettings" fill="none" width={16} height={16} />
      //         <Typography
      //           variant="body4"
      //           children="Minha conta"
      //           style={{ fontWeight: 700, width: "200px" }}
      //         />
      //       </div>
      //     </div>
      //   ),
      // },
      {
        onClick: () => {
          dispatchLogout({});
        },
        children: (
          <div
            role="presentation"
            style={{
              display: "flex",
              alignContent: "center",
            }}
            onClick={() => setIsOpenPopConfirm(!isOpenPopConfirm)}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <Icons name="IconLogout" fill="none" width={16} height={16} />
              <Typography
                variant="body4"
                children="Sair"
                style={{ fontWeight: 700, width: "200px" }}
              />
            </div>
          </div>
        ),
      },
    ],
    [isOpenPopConfirm, employeeId, navigate]
  );

  return (
    <StyledMenu onClick={() => {}} type="default" options={employeeOptions}>
      <OptionsButton icon="IconUser" size="small" variant="filled" />
    </StyledMenu>
  );
};

export default ProfileDropdown;
