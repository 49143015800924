import { useState, useMemo } from "react";
import { MenuItemMobile } from "./..";
import { MenuGroupsProps, Notifications } from "../Menu/types";
import {
  Container,
  ItemWrapper,
  ItemContainer,
  IconContainer,
  StyledIcon,
  Title,
  MenusContainer,
} from "./styles";
import { useTranslation } from "react-i18next";
import { useNotificationsMenuContext } from "../../context/notification";

interface GroupProps extends MenuGroupsProps {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const GroupMobile = ({ name, menus, onClick }: GroupProps) => {
  const [open, setOpen] = useState<boolean>(true);
  const [translations] = useTranslation("translations");
  const { notifications } = useNotificationsMenuContext();

  const groupName = useMemo(() => {
    const splittedName = name.split(" ");
    const lastIndex = splittedName.length ? splittedName.length - 1 : 0;

    return splittedName[lastIndex];
  }, [name]);

  return (
    <Container>
      <ItemWrapper>
        <ItemContainer onClick={() => setOpen(!open)}>
          <Title variant="caption">{translations(`menu.${name}`)}</Title>
          <IconContainer open={open}>
            <StyledIcon name="IconChevronDown" size={16} primary={false} />
          </IconContainer>
        </ItemContainer>
      </ItemWrapper>
      <MenusContainer showMenus={open}>
        {open &&
          menus.map((menu) => {
            return (
              <MenuItemMobile
                key={menu.name}
                icon={menu.icon}
                to={menu.route}
                name={menu.name}
                isNew={menu.isNew}
                subMenus={menu.subMenus}
                groupName={groupName}
                isLoading={menu.isLoading}
                onClick={onClick}
                onClickMenuItem={menu.onClick}
                notification={notifications?.[menu.notificationKey] ?? null}
              />
            );
          })}
      </MenusContainer>
    </Container>
  );
};

export default GroupMobile;
