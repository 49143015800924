import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const StyledListItem = styled.li`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs4};
  list-style: none;
  padding-left: 12px;

  &:not(:last-child) {
    padding-bottom: 12px;
  }
`;

const ItemContainer = styled(NavLink)`
  display: flex;
  align-items: center;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  user-select: none;

  &:hover {
    p {
      color: ${({ theme }) => theme.colors.secondary.dark.dark5};
    }
    svg {
      stroke: ${({ theme }) => theme.colors.secondary.dark.dark5};
    }
  }

  &.active {
    p {
      color: ${({ theme }) => theme.colors.secondary.dark.dark5};
    }

    svg {
      stroke: ${({ theme }) => theme.colors.secondary.dark.dark5} !important;
    }
  }
`;

const Title = styled(Typography)`
  display: flex;
  transition: opacity 0.3s ease-in;
  overflow: hidden;
  justify-content: flex-start;
  flex: 1;
  margin: 0px 1px;
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};

  && {
    font-weight: 600;
    line-height: 1.2;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
`;

export { ItemContainer, StyledListItem, Title };
