import { useState, useCallback, useEffect, useRef, useContext } from "react";
import { Skeleton } from "@mui/material";
import { default as ProfileDropdown } from "./ProfileDropdown";
import {
  Notifications,
  SelectedProfile,
  MenusWithDividers,
  NewProduct,
} from "../types";
import { Context } from "../../../context";
import {
  Container,
  LeftContainer,
  ArrowButton,
  CenterContainer,
  LogoContainer,
  Logo,
  RightContainer,
  AbsoluteOverlay,
  MenuContainer,
  ScrolledMenus,
  ProfileToggleContainer,
} from "./styles";
import LogoFlash from "../../../assets/logo_flash.svg";
import { SectionLoading } from "../../Common";
import MenuItem from "../Desktop/MenuItem";
import Group from "../Desktop/Group";
import { MenuGroupDivider } from "../Desktop/styles";
import ProfileToggle from "../Desktop/ProfileToggle";
import NewProducts from "../Desktop/NewProducts";

interface MobileProps {
  menuGroups: { menus: MenusWithDividers; newProducts: NewProduct[] };
  hasSettings: boolean;
  notifications: Notifications;
  selectedProfile: SelectedProfile;
  onSelectProfile: (selectedProfile: SelectedProfile) => void;
  hasAdminProfile: boolean;
}

const Mobile = ({
  menuGroups,
  notifications,
  hasAdminProfile,
  onSelectProfile,
  selectedProfile,
}: MobileProps) => {
  const { company } = useContext(Context);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuContainerHeight, setMenuContainerHeight] = useState(0);
  const [profileToggleExpanded, setProfileToggleExpanded] = useState(false);

  const ref = useRef(null);
  const handleMenuOpen = useCallback(() => {
    setMenuOpen((prevMenuOpen) => !prevMenuOpen);
  }, []);

  useEffect(() => {
    setMenuContainerHeight(ref.current.clientHeight);
  }, []);

  const handleProfileToggleExpand = () => {
    setProfileToggleExpanded((prevState) => !prevState);
  };

  const handleSelectProfile = (profile: SelectedProfile) => {
    onSelectProfile(profile);
    setProfileToggleExpanded(false);
  };

  return (
    <Container ref={ref}>
      <LeftContainer>
        <ArrowButton
          size="small"
          variant="line"
          icon={menuOpen ? "IconX" : "IconMenu2"}
          onClick={handleMenuOpen}
        />
      </LeftContainer>
      <CenterContainer>
        <LogoContainer>
          <SectionLoading url={"getCompany"}>
            {({ loading }) =>
              loading ? (
                <Skeleton width="100%" height="100%" />
              ) : company?.logo ? (
                <Logo
                  $active={menuOpen}
                  src={company.logo.path}
                  alt="logo"
                  loading="lazy"
                />
              ) : (
                <LogoFlash />
              )
            }
          </SectionLoading>
        </LogoContainer>
      </CenterContainer>
      <RightContainer>
        <ProfileDropdown />
      </RightContainer>
      <AbsoluteOverlay
        $active={menuOpen}
        menuHeight={menuContainerHeight}
        onClick={handleMenuOpen}
      />
      <MenuContainer
        $active={menuOpen}
        menuHeight={menuContainerHeight}
        onClick={(e) => e.stopPropagation()}
      >
        <ProfileToggleContainer>
          <ProfileToggle
            onHeaderClick={handleProfileToggleExpand}
            isExpanded={profileToggleExpanded}
            selectedProfile={selectedProfile}
            onSelectProfile={handleSelectProfile}
            menuOpen={menuOpen}
            hasAdminProfile={hasAdminProfile}
          />
        </ProfileToggleContainer>
        <ScrolledMenus>
          <ul>
            {menuGroups.menus.map((item) => {
              if (typeof item === "string") {
                return (
                  <MenuGroupDivider
                    marginBottom={item === "bottomDivider"}
                    marginTop={item === "topDivider"}
                  />
                );
              }
              if (!!("menus" in item)) {
                return (
                  <Group
                    key={item.id}
                    id={item.id}
                    name={item.name}
                    menus={item.menus}
                    notifications={notifications}
                    sideBarOpen={menuOpen}
                    onClick={handleMenuOpen}
                  />
                );
              }
              return (
                <MenuItem
                  key={item.name}
                  sideBarOpen={menuOpen}
                  icon={item.icon}
                  to={item.route}
                  name={item.name}
                  isNew={item.isNew}
                  subMenus={item.subMenus}
                  isLoading={item.isLoading}
                  onClick={handleMenuOpen}
                />
              );
            })}
            <NewProducts products={menuGroups.newProducts} />
          </ul>
        </ScrolledMenus>
      </MenuContainer>
    </Container>
  );
};

export default Mobile;
