import styled from "styled-components";
import { IconButton } from "@flash-tecnologia/hros-web-ui-v2";

const Container = styled.div`
  height: 100%;
  transition: width 0.2s ease;
  min-width: 100px;
`;

const InnerContainer = styled.div<{ active: boolean; expanded: boolean }>`
  ${({ expanded }) => !expanded && `position: absolute; left: 0; z-index: 999`};
  background: ${({ theme }) => theme.colors.secondary[99]};
  height: 100%;
  transition: width 0.2s ease-in;
  width: ${({ active }) => (active ? "240" : "100")}px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: ${({ theme }) => theme.spacings.xs};
  border-right: 1px solid rgba(74, 78, 87, 0.1);
`;

const TopContainer = styled.div<{ active: boolean }>`
  margin: 0 16px 4px 20px;
  height: 56px;
  display: flex;
  justify-content: ${({ active }) => (active ? "space-between" : "center")};
  flex-direction: ${({ active }) => (active ? "row" : "column")};
  align-items: center;
  transition: 0.3s ease-in;
`;

const LogoContainer = styled.div`
  height: 20px;
  width: 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Logo = styled.img<{ active: boolean }>`
  object-fit: contain;
  object-position: ${({ active }) => (active ? 0 : "50%")};
  width: 100%;
  height: 100%;
`;

const ArrowButton = styled(IconButton)<{ active: boolean }>`
  margin-top: ${({ active }) => (active ? "0px" : "20px")};
  transition: 0.3s ease-in;
  && {
    &.MuiButtonBase-root {
      width: 40px;
      height: 40px;
      aspect-ratio: auto;
      align-self: center;
      border: 1px solid ${({ theme }) => theme.colors.secondary[95]};
    }
    &.MuiButtonBase-root:hover {
      background: ${({ theme }) => theme.colors.secondary[95]};
    }
    &.MuiButtonBase-root svg {
      stroke: ${({ theme }) => theme.colors.secondary[50]};
    }
  }
`;

const MenusContainer = styled.ul`
  position: relative;
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  background: ${({ theme }) => theme.colors.neutral[100]};
  border-radius: ${({ theme }) =>
    `${theme.spacings.xs3} ${theme.spacings.xs3} 0 0`};
  padding: ${({ theme }) => `${theme.spacings.xs2} 0`};
  transition: opacity 0.3s ease;
  display: flex;
  flex-direction: column;
`;

const ProfileToggleContainer = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacings.xs2}`};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MenuGroupDivider = styled.div<{
  marginBottom?: boolean;
  marginTop?: boolean;
}>`
  ${({ marginBottom, theme }) =>
    marginBottom && `margin-bottom: ${theme.spacings.xs4}`};
  ${({ marginTop, theme }) => marginTop && `margin-top: ${theme.spacings.xs4}`};
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => `${theme.colors.neutral[95]}`};
`;

export {
  InnerContainer,
  Container,
  TopContainer,
  LogoContainer,
  Logo,
  ArrowButton,
  MenusContainer,
  ProfileToggleContainer,
  MenuGroupDivider,
};
