import {
  setInLS,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";
import { useEffect } from "react";
import useSWR from "swr";
import { getExpensesContracts } from "../api";
import { IExpensesContracts } from "../components/Menu/types";

export function useExpensesContracts() {
  const { selectedCompany } = useSelectedCompany();
  const {
    data: response,
    isLoading,
    isValidating,
  } = useSWR<IExpensesContracts>(
    selectedCompany.id ? `expenses-company-data-${selectedCompany.id}` : null,
    () => getExpensesContracts(selectedCompany.id),
    {
      errorRetryCount: 3,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  useEffect(() => {
    if (!isLoading && response?.contracts) {
      setInLS({
        key: `expensesAcceptanceTermsCompanyId-${selectedCompany.id}`,
        value: response.contracts,
      });
      setInLS({
        key: `expensesPlanFeatures-${selectedCompany.id}`,
        value: response.features,
      });
    }
  }, [response, isLoading]);

  return {
    expensesContractsData: { ...response, isLoading },
    isLoading,
    isValidating,
  };
}
