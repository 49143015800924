import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const StyledContainer = styled.div`
  padding: ${({ theme }) => `${theme.spacings.xs4} ${theme.spacings.xs4}`};
  margin: ${({ theme }) =>
    `${theme.spacings.s} ${theme.spacings.xs4} ${theme.spacings.xs4}`};
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.borders.radius.s};
  border: ${({ theme }) => `1px solid ${theme.colors.secondary[70]}`};
  background-color: ${({ theme }) => theme.colors.secondary[99]};
`;

const StyledCollapsedContainer = styled.div`
  padding: ${({ theme }) => `${theme.spacings.xs4} ${theme.spacings.xs4}`};
  margin: ${({ theme }) =>
    `${theme.spacings.s} ${theme.spacings.xs4} ${theme.spacings.xs4}`};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.borders.radius.s};
  border: ${({ theme }) => `1px solid ${theme.colors.secondary[70]}`};
  background-color: ${({ theme }) => theme.colors.secondary[99]};
`;

const StyledHorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.spacings.xs4} ${theme.spacings.xs4}`};
`;

const StyledTitle = styled(Typography)<{ neutral?: boolean }>`
  display: block;
  color: ${({ theme, neutral }) =>
    neutral ? theme.colors.neutral[40] : theme.colors.secondary[50]};
  font-weight: 700;
  transition: opacity 0.3s ease;
  && {
    line-height: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
`;
const StyledProductName = styled(Typography)`
  display: block;
  color: ${({ theme }) => theme.colors.neutral[40]};
  font-weight: 700;
  transition: opacity 0.3s ease;
  && {
    line-height: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
`;

const StyledProductContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: ${({ theme }) => `${theme.spacings.xs4} ${theme.spacings.xs4}`};
`;

const StyledIcon = styled(Icons)<{ neutral?: boolean }>`
  color: ${({ theme, neutral }) =>
    neutral ? theme.colors.neutral[40] : theme.colors.secondary[50]};
  fill: transparent;
`;
export {
  StyledContainer,
  StyledCollapsedContainer,
  StyledTitle,
  StyledHorizontalContainer,
  StyledIcon,
  StyledProductContainer,
  StyledProductName,
};
