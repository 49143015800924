import { useNotificationsMenuContext } from "../../context/notification";
import { SubMenuProps } from "../Menu/types";
import { NewTag } from "../MenuItem/styles";
import { NewTagText } from "../MenuItemMobile/styles";

import { ItemContainer, StyledListItem, Title } from "./styles";

const SubMenuItemMobile = ({
  name,
  route,
  onClick,
  notificationKey,
}: SubMenuProps) => {
  const { notifications } = useNotificationsMenuContext();
  const notification = notifications?.[notificationKey];

  return (
    <StyledListItem onClick={onClick}>
      <ItemContainer to={route}>
        <Title variant="body4">{name}</Title>
      </ItemContainer>
      {Boolean(notification?.value) && (
        <NewTag>
          <NewTagText variant="caption" tag="p">
            {notification.value}
          </NewTagText>
        </NewTag>
      )}
    </StyledListItem>
  );
};

export default SubMenuItemMobile;
