import { IContext } from "./types";

const initialState: IContext = {
  loading: { urls: [] },
  company: {},
  contracts: [],
  dispatchAction: () => null,
};

export { initialState };
