import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

const StyledListItem = styled.li`
  list-style: none;
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs4};
  padding: ${({ theme }) =>
    `${theme.spacings.xs4} 0 ${theme.spacings.xs4} ${theme.spacings.xs3}`};
`;

const ItemContainer = styled(NavLink)`
  display: flex;
  align-items: center;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  user-select: none;

  &:hover {
    p {
      color: ${({ theme }) => theme.colors.secondary.dark.dark5};
    }
    svg {
      stroke: ${({ theme }) => theme.colors.secondary.dark.dark5};
    }
  }

  &.active {
    p {
      color: ${({ theme }) => theme.colors.secondary.dark.dark5};
    }

    svg {
      stroke: ${({ theme }) => theme.colors.secondary.dark.dark5} !important;
    }
  }
`;

const Title = styled(Typography)<{ $sideBarOpen: boolean }>`
  display: block;
  display: -webkit-box;
  opacity: ${({ $sideBarOpen }) => ($sideBarOpen ? 1 : 0)};
  transition: opacity 0.3s ease-in;
  overflow: hidden;
  justify-content: flex-start;
  flex: 1;
  margin: 0px 1px;
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};

  ${({ $sideBarOpen }) =>
    !$sideBarOpen &&
    `
  width: 0;
  height: 0;
  flex: 0;
  `}

  && {
    font-weight: 600;
    line-height: 1.2;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
`;

export { StyledListItem, ItemContainer, Title };
