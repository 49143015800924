import styled from "styled-components";
import { Typography, Icons } from "@flash-tecnologia/hros-web-ui-v2";

const StyledColapsedContainer = styled.div`
  padding: ${({ theme }) => `${theme.spacings.xs4} ${theme.spacings.xs}`};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.spacings.xs4};
  background-color: ${({ theme }) => theme.colors.secondary[95]};
`;

const StyledContainer = styled.div`
  padding: ${({ theme }) => `${theme.spacings.xs4} ${theme.spacings.xs4}`};
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.spacings.xs4};
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  transition: height 1s ease;
`;

const HeaderContainer = styled.div<{ isExpanded: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  ${({ isExpanded, theme }) =>
    isExpanded && `padding-top: ${theme.spacings.xs5}`};
`;
const StyledHorizontalBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs4};
`;
const StyledVerticalBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled(Typography)<{ neutral?: boolean }>`
  display: block;
  color: ${({ theme, neutral }) =>
    neutral ? theme.colors.neutral[40] : theme.colors.secondary[50]};
  font-weight: 700;
  line-height: 1.2;
  && {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
`;
const Description = styled(Typography)<{ neutral?: boolean }>`
  display: block;
  overflow: hidden;
  color: ${({ theme, neutral }) =>
    neutral ? theme.colors.neutral[50] : theme.colors.primary};
  font-weight: 600;
`;

const StyledIcon = styled(Icons)<{ neutral?: boolean }>`
  color: ${({ theme, neutral }) =>
    neutral ? theme.colors.neutral[40] : theme.colors.secondary[50]};
  fill: transparent;
`;

const StyledIconWrapper = styled.div<{ isExpanded?: boolean }>`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.secondary[90]};
  padding: ${({ theme }) => theme.spacings.xs5};
  width: 24px;
  height: 24px;
`;
const StyledIconContainer = styled.div<{ isExpanded?: boolean }>`
  max-width: 100px;
  overflow: hidden;
  transition: max-width 0.1s ease;
  line-height: 1.2;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  ${({ isExpanded }) => isExpanded && `max-width: 0`};
`;

const StyledProfileItemContainer = styled.div<{ isSelected?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-radius: ${({ theme }) => theme.spacings.xs4};
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  padding: ${({ theme }) => theme.spacings.xs3};
  border: 1px solid transparent;
  cursor: pointer;
  ${({ theme, isSelected }) =>
    isSelected &&
    `background-color: ${theme.colors.secondary[99]}; border: 1px solid ${theme.colors.secondary[50]}`}
`;

const StyledExpandIconContainer = styled.div<{ isExpanded: boolean }>`
  display: flex;
  position: absolute;
  top: ${({ theme }) => theme.spacings.xs5};
  right: ${({ theme }) => theme.spacings.xs5};
  transform: ${({ isExpanded }) =>
    isExpanded ? "rotate(180deg)" : "rotate(0deg)"};
  transition: transform 0.2s ease;
`;

const StyledProfileItemsContainer = styled.div<{ isExpanded?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: ${({ theme }) => `${theme.spacings.xs3}`};
  overflow: hidden;
  max-height: 500px; //TODO: replace for safe value
  transition: max-height 0.2s ease;
  padding-top: ${({ theme }) => `${theme.spacings.xs4}`};
  ${({ isExpanded }) =>
    !isExpanded &&
    `max-height: 0; padding-top: 0; 
`};
`;

export {
  Title,
  HeaderContainer,
  StyledContainer,
  StyledIcon,
  StyledIconContainer,
  StyledHorizontalBox,
  StyledVerticalBox,
  Description,
  StyledProfileItemContainer,
  StyledProfileItemsContainer,
  StyledIconWrapper,
  StyledExpandIconContainer,
  StyledColapsedContainer,
};
