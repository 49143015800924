import {
  Axios,
  getAccessTokenPayloadSync,
  getFromLS,
} from "@flash-tecnologia/hros-web-utility";
import { useEffect, useState } from "react";

export function useCheckCorporateCardUser() {
  const [isCorporateCardUser, setIsCorporateCardUser] = useState(false);
  const getAccessToken = getAccessTokenPayloadSync;

  const checkIsCorporateCardUser = async () => {
    const { employeeId } = getAccessToken();

    try {
      const { data } = await Axios({
        service: "corporateCardBff",
        method: "get",
        url: `/user.checkCorporateCard?batch=0&input=${JSON.stringify({
          0: {
            json: {
              employeeId,
            },
          },
        })}`,
      });
      return data[0].result.data.json.corporateCardEnabled;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    (async () => {
      const checkCorporateCardUserResponse = await checkIsCorporateCardUser();

      setIsCorporateCardUser(checkCorporateCardUserResponse);
    })();
  }, []);

  return { isCorporateCardUser };
}
