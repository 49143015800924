import { useMemo, useState, useCallback } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { IconTypes } from "@flash-tecnologia/hros-web-ui-v2";
import {
  StyledListItem,
  StyledIcon,
  Title,
  StyledLink,
  StyledRedirectLink,
  IconContainer,
  MenuContainer,
  SubMenus,
  FlexColumn,
  FlexCenter,
  ItemSkeleton,
  NotificationContainer,
  NotificationText,
} from "./styles";
import { useTranslation } from "react-i18next";
import { NotificationItem, SubMenuProps } from "../../types";
import SubMenuItem from "../SubMenuItem";

interface Item {
  sideBarOpen: boolean;
  icon: IconTypes;
  to: string;
  name: string;
  subMenus?: SubMenuProps[];
  isNew?: boolean;
  isLoading?: boolean;
  notification?: NotificationItem;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onClickMenuItem?: () => void;
  marginTop?: boolean;
  className?: string;
}

const MenuItem = ({
  sideBarOpen,
  name,
  icon,
  subMenus,
  to,
  isLoading,
  notification,
  marginTop,
  onClick,
  onClickMenuItem,
  className,
}: Item) => {
  const location = useLocation();
  const [translations] = useTranslation("translations");

  const isItemActive = () => {
    if (subMenus?.length) {
      return subMenus.some(({ route }) =>
        matchPath({ path: route, end: false }, location.pathname)
      );
    }

    if (!to) return false;

    // Create a fake URL object to get the pathname
    // and to allow paths to contain query params
    const url = new URL(to, "http://just-to-create-url-object.com");

    return Boolean(
      matchPath({ path: url.pathname, end: false }, location.pathname)
    );
  };

  const isActive = isItemActive();

  const isExpandable = useMemo(
    () => Boolean(subMenus && subMenus.length),
    [subMenus]
  );

  const isSubMenuActive: boolean = useMemo(() => {
    if (!isExpandable) return false;
    return subMenus.some((subMenu) => {
      return Boolean(
        matchPath({ path: subMenu.route, end: false }, location.pathname)
      );
    });
  }, [isExpandable, location?.pathname, subMenus]);

  const [open, setOpen] = useState<boolean>(isSubMenuActive);

  const ItemContainer: any = useMemo(() => {
    if (isExpandable) return MenuContainer;

    const isRedirectLink = to.includes("https://");
    return isRedirectLink ? StyledRedirectLink : StyledLink;
  }, [isExpandable]);

  const showExpandable = useMemo(
    () => Boolean(sideBarOpen && isExpandable),
    [sideBarOpen, isExpandable]
  );

  const handleExpandableMenuClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (!sideBarOpen) {
        onClick?.(e);
        return;
      }
      setOpen(!open);
    },
    [open, isExpandable, sideBarOpen, onClick]
  );

  const handleMenuClick = (e: React.MouseEvent<HTMLElement>) => {
    if (isExpandable) {
      handleExpandableMenuClick(e);
    } else {
      onClickMenuItem?.();
    }
  };

  if (isLoading) {
    return (
      <StyledListItem
        open={open}
        className={className}
        isActive={isActive}
        isExpandable={isExpandable}
        marginTop={marginTop}
      >
        <FlexColumn sideBarOpen={sideBarOpen}>
          <ItemSkeleton variant="text" />
        </FlexColumn>
      </StyledListItem>
    );
  }

  return (
    <>
      <StyledListItem
        open={open}
        isActive={isActive}
        isExpandable={isExpandable}
        marginTop={marginTop}
      >
        <FlexColumn sideBarOpen={sideBarOpen}>
          <ItemContainer
            to={to}
            href={to}
            target={to?.includes("https://") ? "_blank" : "_self"}
            $sideBarOpen={sideBarOpen}
            $active={isActive || (!sideBarOpen && Boolean(notification?.value))}
            onClick={handleMenuClick}
            $hasNotification={Boolean(notification?.value)}
          >
            <StyledIcon
              name={icon}
              size={16}
              primary
              sideBarOpen={sideBarOpen}
            />
            <FlexCenter $sideBarOpen={sideBarOpen}>
              <Title variant="body4" $sideBarOpen={sideBarOpen}>
                {translations(`menu.${name}`)}
              </Title>
            </FlexCenter>
            {notification && (
              <NotificationContainer>
                <NotificationText variant="caption">
                  {notification.value}
                </NotificationText>
              </NotificationContainer>
            )}
            {isExpandable && sideBarOpen && (
              <IconContainer open={open}>
                <StyledIcon
                  name="IconChevronDown"
                  size={16}
                  primary={false}
                  invisible={!isExpandable}
                />
              </IconContainer>
            )}
          </ItemContainer>
        </FlexColumn>
      </StyledListItem>
      <SubMenus $active={showExpandable && open}>
        {showExpandable &&
          open &&
          subMenus?.map((subMenu) => (
            <SubMenuItem
              key={subMenu.name}
              name={translations(`menu.${subMenu.name}`)}
              route={subMenu.route}
              onClick={subMenu?.onClick}
              sideBarOpen={sideBarOpen}
              isNew={subMenu?.isNew}
            />
          ))}
      </SubMenus>
    </>
  );
};

export default MenuItem;
